<template>
  <div class="error-wrapper">
    <h1>Техническая ошибка</h1>
    <div class="error-message">{{ $route.query.message }}</div>
    <button @click="toPaymentPage">
      На страницу оплаты
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    toPaymentPage() {
      try {
        window.top.location.reload()
      } catch (e) {
        window.top.postMessage('reload-page', '*')
      }
    },
  }
}
</script>

<style scoped>
.error-wrapper {
  margin: 120px auto;
  max-width: 400px;
}
h1 {
  font-size: 28px;
}
.error-message {
  font-size: 16px;
  margin: 24px 0;
}
button {
  background: #ee2d36;
  color: #fff;
  padding: 14px 28px;
  border: 0;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
</style>
