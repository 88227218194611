<template>
  <div class="espp-result-wrapper" v-if="resultFetched">
    <template v-if="result">
      <h2>
        <img src="@/assets/img/espp/loader.svg" v-if="!result.finalState" />
        <img src="@/assets/img/espp/success.svg" v-else-if="result.success" />
        <img src="@/assets/img/espp/error.svg" v-else />
        {{ result.stateDescription }}
      </h2>
      <div class="espp-result-row" v-for="row in result.data" :key="row.name" :class="row.name">
        <div class="espp-result-label">{{ row.label }}</div>
        <div class="espp-result-spacer" />
        <div class="espp-result-value">{{ formatRowValue(row.name, row.value) }}</div>
      </div>
    </template>
    <h2 v-else>
      <img src="@/assets/img/espp/error.svg" />
      Не удалось получить данные платежа
    </h2>
    <div class="espp-result-error-msg">{{ errorMsg }}</div>
    <div class="espp-result-buttons">
      <button @click="toPaymentPage" v-if="!result || result.finalState">На страницу оплаты</button>
      <loading-btn class="espp-autopay" @click="saveCard" :loading="savingCard" v-if="$auth.check('CAN_USE_AUTOPAY') && result && result.success && result.canSaveCard">
        Настроить автопополнение
      </loading-btn>
    </div>
  </div>
  <div class="preloader" v-else>
    <loading-spinner />
  </div>
</template>

<script>
import dateFormat from 'dateformat'
import api from '@/api'

export default {
  data() {
    return {
      result: null,
      resultFetched: false,
      savingCard: false,
      errorMsg: '',
    }
  },
  computed: {
    srcPayId() {
      return new URLSearchParams(location.search).get('srcPayId')
    }
  },
  created() {
    this.fetchResult()
  },
  methods: {
    fetchResult() {
      api.esppResult(this.srcPayId)
        .then(data => {
          this.result = data
          this.resultFetched = true
          return data
        })
        .catch(() => ({}))
        .then(data => {
          if (!data.finalState) {
            setTimeout(() => this.fetchResult(), 5000)
          }
        })
    },
    formatRowValue(name, value) {
      if (name === 'date') {
        return dateFormat(value, 'dd.mm.yy HH:MM')
      }
      if (name === 'amount') {
        return Number(value).toFixed(2) + ' руб.'
      }
      return value
    },
    toPaymentPage() {
      try {
        window.top.location.reload()
      } catch (e) {
        window.top.postMessage('reload-page', '*')
      }
    },
    saveCard() {
      this.errorMsg = ''
      this.savingCard = true
      api.esppSaveCard(this.srcPayId)
        .then(data => {
          if (data.success) {
            window.top.location = this.$router.resolve({name: 'bankcard-autopay'}).href
          } else {
            this.errorMsg = data.message
          }
        })
        .catch(() => this.errorMsg = 'Произошла ошибка при сохранении карты. Попробуйте позже.')
        .then(() => this.savingCard = false)
    }
  },
}
</script>


<style lang="scss" scoped>
.espp-result {
  &-wrapper {
    width: 600px;
    margin: 120px auto;
    font-size: 16px;
    h2 {
      margin-bottom: 32px;
    }
    img {
      vertical-align: middle;
    }
    button {
      background: #ee2d36;
      color: #fff;
      padding: 14px 28px;
      border: 0;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 16px;
      cursor: pointer;
    }
  }
  &-row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 20px;
    &.amount .espp-result-value {
      font-size: 20px;
    }
  }
  &-spacer {
    border-bottom: dotted 2px #B8B9C0;
    flex: 1;
  }
  &-buttons {
    display: flex;
    justify-content: space-between;
    .espp-autopay {
      background: #6c4099;
    }
  }
  &-error-msg {
    color: red;
    text-align: center;
    margin-top: 20px;
    height: 40px;
    margin-bottom: 10px;
  }
}
</style>
